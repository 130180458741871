import React from "react";
import languages from "../../languages";
import "react-circular-progressbar/dist/styles.css";
import classnames from "classnames";

import Select from "react-select";

import $ from "jquery";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
} from "reactstrap";
import RequestServer, { showModalErrorCode } from "../../RequestServer";
import Loading from "../components/Loading";
import {
  getError,
  getLang,
  validateEmail,
  validateMobile,
  ValidationErrors,
} from "../../Values";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { showModal } from "../../actions";
import errorsStrings from "../../errorsStrings";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "./utils";
import CreditCard from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

class PaymentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      focus: null,
      detail: undefined,
      hasError: false,
      errorText: "I will be the leader of a company that ends up being",
      showForm: false,
      loadingForm: false,
      bootData: {},
      currencies: [],
      selCurrency: null,
      selCurrencyErr: "",
      amount: "",
      amountErr: "",
      name: "",
      nameErr: "",
      family: "",
      familyErr: "",
      email: "",
      emailErr: "",
      mobile: "",
      mobileErr: "",
      address: "",
      addressErr: "",
      country: "",
      countryErr: "",
      postalCode: "",
      postalCodeErr: "",
      city: "",
      cityErr: "",
      description: "",
      descriptionErr: "",
      cvc: "",
      cvcErr: "",
      expiry: "",
      expiryErr: "",
      cardName: "",
      cardNameErr: "",
      number: "",
      numberErr: "",
      sweetAlert: false,
      formData: null,
      DangerSweetAlert: false,
      tryAmount: 0,
      trySweetAlert: false,
      rate: 0,
      turkishID: "",
      turkishIDErr: "",
    };
  }

  change = (event, stateName) => {
    let value = event;
    let err = "";
    switch (stateName) {
      case "amount":
        value = event.value;
        err =
          parseFloat(value) > 0 ? "ss" : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;

      case "selCurrency":
        value = event;
        err = value ? "ss" : ValidationErrors.formImportant;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;

      case "name":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({
          [stateName]: value.replace(/[^A-Za-z ]/gi, ""),
          [stateName + "Err"]: err,
        });
        break;

      case "family":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({
          [stateName]: value.replace(/[^A-Za-z ]/gi, ""),
          [stateName + "Err"]: err,
        });
        break;

      case "address":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "turkishID":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;

      case "country":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "postalCode":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "city":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "description":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputRequired;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "email":
        value = event.target.value;
        err = validateEmail(value)
          ? "ss"
          : errorsStrings[this.lang].inputIsNotValid;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      case "mobile":
        value = event.target.value;
        err =
          value.trim().length > 1
            ? "ss"
            : errorsStrings[this.lang].inputIsNotValid;
        this.setState({ [stateName]: value, [stateName + "Err"]: err });
        break;
      default:
        break;
    }
  };

  getClass = (stateName) => {
    const err = this.state[stateName + "Err"];
    let classErr = "has-danger";
    if (err === "ss") classErr = "has-success";
    else if (err === "") classErr = "";
    return classnames(classErr, {
      "input-group-focus mx-auto": this.state.focus === stateName,
    });
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };
  handleInputFocus = ({ target }) => {
    this.setState({ focused: target.name });
  };
  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }
    this.setState({ [target.name]: target.value });
  };

  renderError = (stateName) => {
    const err = this.state[stateName + "Err"];
    if (err !== "" && err !== "ss")
      return <label className="error">{err}</label>;
  };

  componentDidMount() {
    document.body.classList.toggle("login-page");
    $("body").addClass("rtl");
    $("html").addClass("rtl-active");
    this.request();
  }

  checkIPRequest = () => {
    RequestServer("post", "check-country-code", (response) => {}, {
      post: {},
      lang: this.lang,
      thisProps: { data: this.state.bootData, showModal: this.props.showModal },
      onAnyFailed: () => {
        this.setState({ loading: false });
      },
    });
  };

  request = () => {
    RequestServer(
      "post",
      "boot",
      (response) => {
        this.setState({ bootData: response });
        this.makeRemoteRequest(response);
        // this.checkIPRequest();
      },
      {
        post: { secret: "AppYek**175GHD@#" },
        lang: this.lang,
        onAnyFailed: () => {
          this.setState({ loading: false });
        },
      }
    );
  };

  makeRemoteRequest = (bootData) => {
    RequestServer(
      "post",
      "personal-payment-gateway",
      (response) => {
        let currencies = [];
        if (response && response.irr_personal_gateway_status === "Active") {
          response.wallets.forEach((resItem) => {
            const currency = bootData.currencies.find(
              (item) => item.id === resItem.currency_id
            );
            if (
              (currency && resItem.currency_id === 2) ||
              (currency && resItem.currency_id === 18) ||
              (currency && resItem.currency_id === 3) ||
              (currency && resItem.currency_id === 20)
            )
              currencies.push({ label: currency.code, value: currency });
          });
        } else {
          response.wallets.forEach((resItem) => {
            const currency = bootData.currencies.find(
              (item) => item.id === resItem.currency_id
            );
            if (
              (currency && resItem.currency_id === 2) ||
              (currency && resItem.currency_id === 18) ||
              (currency && resItem.currency_id === 20)
            )
              currencies.push({ label: currency.code, value: currency });
          });
        }

        this.setState({ detail: response, loading: false });
      },
      {
        post: { username: this.props.match.params.username },
        lang: this.lang,
        thisProps: { data: bootData, showModal: this.props.showModal },
        onAnyFailed: () => {
          this.setState({ loading: false });
        },
      }
    );

    RequestServer(
      "post",
      "getCurrencies/personal-payment-gateway",
      (response) => {
        let currencies = [];
        if (response) {
          response.forEach((resItem) => {
            const currency = bootData.currencies.find(
              (item) => item.id === resItem.id
            );
            currencies.push({
              label: currency[`name_${this.lang}`],
              value: currency,
            });
          });
        }
        this.setState({ currencies, loading: false });
      },
      {
        post: { username: this.props.match.params.username },
        lang: this.lang,
        thisProps: { data: bootData, showModal: this.props.showModal },
        onAnyFailed: () => {
          this.setState({ loading: false });
        },
      }
    );
  };

  renderSweetAlertDanger() {
    return (
      <ReactBSAlert
        danger
        style={{ display: "block" }}
        title={languages[this.lang].personalgatewaytitle}
        onConfirm={() =>
          this.setState({ DangerSweetAlert: false, loading: false })
        }
        confirmBtnBsStyle="success"
        confirmBtnText={languages[this.lang].ok}
        btnSize=""
      >
        <p>{languages[this.lang].at_now_just_try_payment_link}</p>
      </ReactBSAlert>
    );
  }

  renderSweetAlert() {
    const { cardName, number, expiry, cvc, focused, issuer, formData } =
      this.state;
    return (
      <ReactBSAlert
        style={{ display: "block" }}
        title={languages[this.lang].enter_credit_card_info}
        closeOnClickOutside={true}
        showConfirm={false}
      >
        <div key="Payment">
          <div className="App-payment">
            <CreditCard
              number={number}
              name={cardName}
              expiry={expiry}
              cvc={cvc}
              focused={focused}
              callback={this.handleCallback}
            />
            <form ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  value={number}
                  type="tel"
                  name="number"
                  className="form-control"
                  placeholder={languages[this.lang].card_number}
                  pattern="[\d| ]{16,16}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={() => this.setState({ focus: null })}
                  onBlur={() => this.setState({ focus: null })}
                />{" "}
                {/*{this.renderError('number')}*/}
              </div>
              <div className="form-group">
                <input
                  value={cardName}
                  type="text"
                  name="cardName"
                  className="form-control"
                  placeholder={languages[this.lang].name_on_card}
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />{" "}
                {/*{this.renderError('selCurrency')}*/}
              </div>
              <div className="row">
                <div className="col-6">
                  <input
                    value={expiry}
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder={languages[this.lang].expire_date}
                    pattern="\d\d/\d\d"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />{" "}
                  {/*{this.renderError('expiry')}*/}
                </div>
                <div className="col-6">
                  <input
                    value={cvc}
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder={languages[this.lang].cvc}
                    pattern="\d{3,4}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />{" "}
                  {/*{this.renderError('cvc')}*/}
                </div>
              </div>
              <input type="hidden" name="issuer" value={issuer} />
              <div className="form-actions">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.validate1}
                >
                  {languages[this.lang].pay}
                </button>
                <p
                  className="text-default block text-center cursor-pointer mt-3"
                  onClick={() => this.setState({ sweetAlert: false })}
                >
                  {languages[this.lang].cancel}
                </p>
              </div>
            </form>
          </div>
        </div>
      </ReactBSAlert>
    );
  }

  renderForm = () => {
    if (this.state.showForm) {
      const {
        name,
        family,
        email,
        mobile,
        address,
        country,
        postalCode,
        city,
        description,
      } = this.state;
      return (
        <Col md="12" className="mt-3 p-0 m-0">
          <h4
            className={
              this.lang === "fa"
                ? "text-right text-danger col-sm-12"
                : "text-left text-primary col-sm-12 paymentTitleDanger"
            }
          >
            <i
              className="fa fa-exclamation-triangle"
              style={{ margin: "0 10px" }}
            ></i>
            {languages[this.lang].yekpay_link_form_title}
          </h4>
          {this.renderBoxError()}
          {/*<Col md="6">*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('name')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-user"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].firstName}*/}
          {/*                    value={name}*/}
          {/*                    onChange={e => this.change(e, 'name')}*/}
          {/*                    onFocus={() => this.setState({focus: 'name'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('name')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('email')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-envelope"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="email"*/}
          {/*                    placeholder={languages[this.lang].email}*/}
          {/*                    value={email}*/}
          {/*                    onChange={e => this.change(e, 'email')}*/}
          {/*                    onFocus={() => this.setState({focus: 'email'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('email')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('address')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-map-marker-alt"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].address}*/}
          {/*                    value={address}*/}
          {/*                    onChange={e => this.change(e, 'address')}*/}
          {/*                    onFocus={() => this.setState({focus: 'address'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('address')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('postalCode')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-mailbox"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].postalcode}*/}
          {/*                    value={postalCode}*/}
          {/*                    onChange={e => this.change(e, 'postalCode')}*/}
          {/*                    onFocus={() => this.setState({focus: 'postalCode'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('postalCode')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*</Col>*/}
          {/*<Col md="6">*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('family')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-user"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].lastName}*/}
          {/*                    value={family}*/}
          {/*                    onChange={e => this.change(e, 'family')}*/}
          {/*                    onFocus={() => this.setState({focus: 'family'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('family')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('mobile')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-mobile"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].mobile}*/}
          {/*                    value={mobile}*/}
          {/*                    onChange={e => this.change(e, 'mobile')}*/}
          {/*                    onFocus={() => this.setState({focus: 'mobile'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('mobile')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('country')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-flag"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].country}*/}
          {/*                    value={country}*/}
          {/*                    onChange={e => this.change(e, 'country')}*/}
          {/*                    onFocus={() => this.setState({focus: 'country'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('country')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*    <Col className="px-0">*/}
          {/*        <InputGroup className={this.getClass('city')}>*/}
          {/*            <InputGroupAddon addonType="prepend" className="col-md-12 px-0">*/}
          {/*                <InputGroupText md="2">*/}
          {/*                    <i className="fal fa-flag"/>*/}
          {/*                </InputGroupText>*/}
          {/*                <Input*/}
          {/*                    type="text"*/}
          {/*                    placeholder={languages[this.lang].city}*/}
          {/*                    value={city}*/}
          {/*                    onChange={e => this.change(e, 'city')}*/}
          {/*                    onFocus={() => this.setState({focus: 'city'})}*/}
          {/*                    onBlur={() => this.setState({focus: null})}/>*/}
          {/*            </InputGroupAddon>*/}
          {/*            {this.renderError('city')}*/}
          {/*        </InputGroup>*/}
          {/*    </Col>*/}
          {/*</Col>*/}
          {/*<Col md="12">*/}
          {/*          <textarea*/}
          {/*              className="custom-textarea"*/}
          {/*              value={description}*/}
          {/*              onChange={e => this.change(e, 'description')}*/}
          {/*              onFocus={() => this.setState({focus: 'description'})}*/}
          {/*              onBlur={() => this.setState({focus: null})}*/}
          {/*              rows="7"*/}
          {/*              placeholder={languages[this.lang].yekpayLinkDPayerescriptionPlaceholder}*/}
          {/*          />*/}
          {/*    {this.renderError('description')}*/}

          {/*</Col>*/}
          <Row className="mx-auto text-center mt-1 hover-cards w-100">
            <div className="w-100 h-85p">
              <Col md="6">
                <InputGroup className={this.getClass("name")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].firstName}
                      value={name}
                      onChange={(e) => this.change(e, "name")}
                      onFocus={() => this.setState({ focus: "name" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("name")}
                </InputGroup>
              </Col>
              <Col md="6">
                <InputGroup className={this.getClass("family")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-user" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].lastName}
                      value={family}
                      onChange={(e) => this.change(e, "family")}
                      onFocus={() => this.setState({ focus: "family" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("family")}
                </InputGroup>
              </Col>
            </div>
            <div className="w-100 h-85p">
              <Col md="6">
                <InputGroup className={this.getClass("email")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-envelope" />
                    </InputGroupText>
                    <Input
                      type="email"
                      placeholder={languages[this.lang].email}
                      value={email}
                      onChange={(e) => this.change(e, "email")}
                      onFocus={() => this.setState({ focus: "email" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("email")}
                </InputGroup>
              </Col>
              <Col md="6">
                <InputGroup className={this.getClass("mobile")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-mobile" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].mobile}
                      value={mobile}
                      onChange={(e) => this.change(e, "mobile")}
                      onFocus={() => this.setState({ focus: "mobile" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("mobile")}
                </InputGroup>
              </Col>
            </div>
            <div className="w-100 h-85p">
              {this.state.selCurrency.value.id === 18 ? (
                <Col md="6">
                  <InputGroup className={this.getClass("turkishID")}>
                    <InputGroupAddon
                      addonType="prepend"
                      className="col-md-12 px-0"
                    >
                      <InputGroupText md="2">
                        <i class="far fa-address-card"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder={languages[this.lang].turkishID}
                        value={this.state.turkishID}
                        onChange={(e) => this.change(e, "turkishID")}
                        onFocus={() => this.setState({ focus: "turkishID" })}
                        onBlur={() => this.setState({ focus: null })}
                      />
                    </InputGroupAddon>
                    {this.renderError("turkishID")}
                  </InputGroup>
                </Col>
              ) : (
                <Col md="6">
                  <InputGroup className={this.getClass("address")}>
                    <InputGroupAddon
                      addonType="prepend"
                      className="col-md-12 px-0"
                    >
                      <InputGroupText md="2">
                        <i className="fal fa-map-marker-alt" />
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder={languages[this.lang].address}
                        value={address}
                        onChange={(e) => this.change(e, "address")}
                        onFocus={() => this.setState({ focus: "address" })}
                        onBlur={() => this.setState({ focus: null })}
                      />
                    </InputGroupAddon>
                    {this.renderError("address")}
                  </InputGroup>
                </Col>
              )}
              <Col md="6">
                <InputGroup className={this.getClass("country")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-flag" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].country}
                      value={country}
                      onChange={(e) => this.change(e, "country")}
                      onFocus={() => this.setState({ focus: "country" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("country")}
                </InputGroup>
              </Col>
            </div>
            <div className="w-100 h-85p">
              <Col md="6">
                <InputGroup className={this.getClass("postalCode")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-mailbox" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].postalcode}
                      value={postalCode}
                      onChange={(e) => this.change(e, "postalCode")}
                      onFocus={() => this.setState({ focus: "postalCode" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("postalCode")}
                </InputGroup>
              </Col>
              <Col md="6">
                <InputGroup className={this.getClass("city")}>
                  <InputGroupAddon
                    addonType="prepend"
                    className="col-md-12 px-0"
                  >
                    <InputGroupText md="2">
                      <i className="fal fa-flag" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={languages[this.lang].city}
                      value={city}
                      onChange={(e) => this.change(e, "city")}
                      onFocus={() => this.setState({ focus: "city" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("city")}
                </InputGroup>
              </Col>
            </div>
          </Row>
          {this.state.selCurrency.value.id === 18 ? (
            <Row>
              <Col>
                <div className="w-100 h-85p">
                  <InputGroup className={this.getClass("address")}>
                    <InputGroupAddon addonType="prepend" className="col-md-12">
                      <InputGroupText md="2">
                        <i className="fal fa-map-marker-alt" />
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder={languages[this.lang].address}
                        value={address}
                        onChange={(e) => this.change(e, "address")}
                        onFocus={() => this.setState({ focus: "address" })}
                        onBlur={() => this.setState({ focus: null })}
                      />
                    </InputGroupAddon>
                    {this.renderError("address")}
                  </InputGroup>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row className="">
            <Col>
              <div className="w-100 h-85p">
                <InputGroup className={this.getClass("description")}>
                  <InputGroupAddon addonType="prepend" className="col-md-12">
                    <InputGroupText md="2">
                      <i className="fal fa-comment-alt" />
                    </InputGroupText>
                    <Input
                      type="textarea"
                      placeholder={
                        languages[this.lang]
                          .yekpayLinkDPayerescriptionPlaceholder
                      }
                      className="custom-textarea"
                      value={description}
                      onChange={(e) => this.change(e, "description")}
                      onFocus={() => this.setState({ focus: "description" })}
                      onBlur={() => this.setState({ focus: null })}
                    />
                  </InputGroupAddon>
                  {this.renderError("description")}
                </InputGroup>
              </div>
            </Col>
          </Row>
        </Col>
      );
    }
  };

  renderButtons = () => {
    if (!this.state.hasError && this.state.showForm) {
      return (
        <Col md="12" className="text-center mt-5">
          <Button
            className="btn-round mx-2 px-5 py-2"
            color="primary"
            size="sm"
            onClick={(e) => this.firstValidate(e)}
          >
            {languages[this.lang].pay}
          </Button>
        </Col>
      );
    }
  };

  renderBoxError = () => {
    if (this.state.hasError) {
      return (
        <Col md="12">
          <p className="error-container mb-3">{this.state.errorText}</p>
        </Col>
      );
    }
  };

  handleForm = () => {
    if (this.state.selCurrency === null)
      this.setState({
        focus: "selCurrency",
        selCurrencyErr: errorsStrings[this.lang].inputRequired,
      });
    if (this.state.amount === "")
      this.setState({
        focus: "amount",
        amountErr: errorsStrings[this.lang].inputRequired,
      });

    this.setState({ showForm: this.state.selCurrency && this.state.amount });
  };

  renderStartButton = () => {
    if (!this.state.showForm) {
      return (
        <Col md="12" className="text-center mt-3">
          <Button
            className="btn-round mx-2 px-5 py-2"
            color="primary"
            size="sm"
            onClick={this.handleForm.bind(this)}
          >
            {languages[this.lang].start}
          </Button>
        </Col>
      );
    }
  };

  trySweetAlert() {
    return (
      <ReactBSAlert
        info
        style={{ display: "block" }}
        title={languages[this.lang].notice}
        onConfirm={() => this.pay(this.state.url)}
        confirmBtnBsStyle="primary"
        confirmBtnText={languages[this.lang].continue}
        btnSize=""
        onCancel={() => this.setState({ trySweetAlert: false })}
        cancelBtnBsStyle="danger"
        cancelBtnText={languages[this.lang].cancel}
        showCancel="warning"
      >
        {/*<p>{languages[this.lang].pay_try}</p>*/}
        <p>
          {" You will pay in " +
            this.state.tryAmount +
            " TRY equivalent of " +
            this.state.detail.amount +
            " EUR (Exchange rate: " +
            this.state.rate +
            "). The charge will appear on your credit card statement as 'Online Payment' or 'BLS* S/ELEKSE YETKILI VEZNE OD' from Turkey. "}
        </p>
      </ReactBSAlert>
    );
  }

  render() {
    this.lang = getLang(this.props);
    const { loading, loadingForm, selCurrency, amount, detail, currencies } =
      this.state;
    return (
      <>
        <div className="py-3">
          {/* <div className="text-center">
                        <img src={require('../../assets/img/YP-logo.png')} width="200"/>
                    </div> */}
          <Row className="text-right mx-0 my-2">
            <Col xl="6" lg="8" md="10" sm="12" className="mx-auto">
              {loading || !detail ? (
                <Card>
                  <CardBody>
                    <Loading lang={this.lang} />
                  </CardBody>
                </Card>
              ) : (
                <Card>
                  <CardHeader className="payment-home-header text-center">
                    <div className="imageDocument mb-2">
                      <div className="imageContainer">
                        <img src={detail.personal_gateway_picture} alt="" />
                      </div>
                    </div>

                    <h4>
                      {detail.first_name} {detail.last_name}
                    </h4>
                  </CardHeader>
                  {loadingForm ? (
                    <CardBody>
                      <Loading lang={this.lang} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <h4 className="my-3 text-center">
                        {languages[this.lang].sendmoney} {detail.first_name}{" "}
                        {detail.last_name}
                      </h4>
                      <Row className="justify-content-md-center">
                        <Col md="6">
                          <InputGroup className={this.getClass("selCurrency")}>
                            <InputGroupAddon
                              addonType="prepend"
                              className="col-md-12 px-0"
                            >
                              <InputGroupText md="2">
                                <i className="fal fa-dollar-sign" />
                              </InputGroupText>
                              <Select
                                className="react-select primary text-center w-100"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                placeholder={languages[this.lang].currency}
                                value={selCurrency}
                                onChange={(e) => this.change(e, "selCurrency")}
                                options={currencies}
                                onFocus={() =>
                                  this.setState({ focus: "selCurrency" })
                                }
                                onBlur={() => this.setState({ focus: null })}
                              />
                            </InputGroupAddon>
                            {this.renderError("selCurrency")}
                          </InputGroup>
                          <div
                            className={`${this.getClass("amount")} ${
                              this.lang === "en" ? "text-left" : "text-right"
                            }`}
                          >
                            <NumberFormat
                              className="payment-home-amount-input"
                              value={amount}
                              onValueChange={(e) => this.change(e, "amount")}
                              onFocus={() => this.setState({ focus: "amount" })}
                              onBlur={() => this.setState({ focus: null })}
                              customInput={Input}
                              thousandSeparator={true}
                              placeholder={
                                selCurrency &&
                                selCurrency.value.type === "Crypto"
                                  ? "0.00000000"
                                  : selCurrency && selCurrency.value.id === 3
                                  ? "0"
                                  : "0.00"
                              }
                              decimalScale={
                                selCurrency && selCurrency.value.id === 3
                                  ? 0
                                  : selCurrency &&
                                    selCurrency.value.type === "Crypto"
                                  ? 8
                                  : 2
                              }
                              fixedDecimalScale={
                                selCurrency && selCurrency.value.id !== 3
                              }
                            />
                            {this.renderError("amount")}
                          </div>
                          {this.renderStartButton()}
                        </Col>
                      </Row>
                      {this.renderForm()}
                      {this.renderButtons()}
                      <Col md="12 mt-3 text-center">
                        {/*<p>*/}
                        {/*{languages[this.lang].paymentlinkdesc}*/}
                        {/*<a href="#" className="mx-2">{languages[this.lang].paymentlinkruleslink}</a>*/}
                        {/*</p>*/}
                      </Col>
                    </CardBody>
                  )}
                </Card>
              )}
            </Col>
          </Row>
        </div>
        <div className="container">
          <div className="col-md-12 text-center">
            <img
              src={require("../../assets/img/card-familys.png")}
              alt="logos"
              width="300"
            />
          </div>
          {/*<div className="col-md-12 text-center mt-2 text-primary">{languages[this.lang].copyright}</div>*/}
        </div>
        {this.state.sweetAlert ? this.renderSweetAlert() : ""}
        {this.state.DangerSweetAlert ? this.renderSweetAlertDanger() : ""}
        {this.state.trySweetAlert ? this.trySweetAlert() : ""}
      </>
    );
  }

  pay = (url) => {
    window.location = url;
  };

  firstValidate = (e) => {
    console.log("clicked");
    const { selCurrency } = this.state;
    if (selCurrency.value.id !== 18) {
      this.validate(e);
    } else {
      this.validateWithTurkishID(e);
    }
  };
  validate = (e) => {
    const {
      bootData,
      selCurrency,
      selCurrencyErr,
      amount,
      amountErr,
      name,
      nameErr,
      family,
      familyErr,
      email,
      emailErr,
      mobile,
      mobileErr,
      address,
      addressErr,
      country,
      countryErr,
      postalCode,
      postalCodeErr,
      city,
      cityErr,
      description,
      descriptionErr,
    } = this.state;
    if (
      selCurrencyErr === "ss" &&
      amountErr === "ss" &&
      nameErr === "ss" &&
      familyErr === "ss" &&
      emailErr === "ss" &&
      mobileErr === "ss" &&
      addressErr === "ss" &&
      countryErr === "ss" &&
      postalCodeErr === "ss" &&
      cityErr === "ss" &&
      descriptionErr === "ss"
    ) {
      this.setState({ loading: true });
      RequestServer(
        "post",
        "personal-payment-gateway/start",
        (response) => {
          this.setState({ url: response.url });
          if (response.redirect) {
            this.pay(response.url);
          } else {
            this.setState({
              tryAmount: response.amount,
              rate: response.rate,
              loading: false,
              companyName: response.company_name,
              trySweetAlert: true,
            });
          }
        },
        {
          post: {
            currency_id: selCurrency.value.id,
            amount,
            payer_first_name: name,
            payer_last_name: family,
            payer_email: email,
            payer_mobile: mobile,
            payer_postal_code: postalCode,
            payer_country: country,
            payer_description: description,
            payer_city: city,
            payer_address: address,
            username: this.props.match.params.username,
            lang: this.lang,
            source: "Website",
          },
          lang: this.lang,
          thisProps: { data: bootData, showModal: this.props.showModal },
          onAnyFailed: () => {
            this.setState({ loadingForm: false });
          },
        }
      );

      e.preventDefault();
    } else {
      if (city === "")
        this.setState({
          focus: "city",
          cityErr: errorsStrings[this.lang].inputRequired,
        });

      if (postalCode === "")
        this.setState({
          focus: "postalCode",
          postalCodeErr: errorsStrings[this.lang].inputRequired,
        });

      if (country === "")
        this.setState({
          focus: "country",
          countryErr: errorsStrings[this.lang].inputRequired,
        });

      if (address === "")
        this.setState({
          focus: "address",
          addressErr: errorsStrings[this.lang].inputRequired,
        });

      if (mobile === "")
        this.setState({
          focus: "mobile",
          mobileErr: errorsStrings[this.lang].inputRequired,
        });

      if (email === "")
        this.setState({
          focus: "email",
          emailErr: errorsStrings[this.lang].inputRequired,
        });

      if (family === "")
        this.setState({
          focus: "family",
          familyErr: errorsStrings[this.lang].inputRequired,
        });

      if (name === "")
        this.setState({
          focus: "name",
          nameErr: errorsStrings[this.lang].inputRequired,
        });

      if (amount === "")
        this.setState({
          focus: "amount",
          amountErr: errorsStrings[this.lang].inputRequired,
        });

      if (selCurrency === "")
        this.setState({
          focus: "selCurrency",
          selCurrencyErr: errorsStrings[this.lang].inputRequired,
        });

      if (description === "")
        this.setState({
          focus: "description",
          descriptionErr: errorsStrings[this.lang].inputRequired,
        });
    }
  };
  validateWithTurkishID = (e) => {
    const params = this.props.match.params;
    const {
      bootData,
      selCurrency,
      selCurrencyErr,
      amount,
      amountErr,
      name,
      nameErr,
      family,
      familyErr,
      email,
      emailErr,
      turkishID,
      turkishIDErr,
      mobile,
      mobileErr,
      address,
      addressErr,
      country,
      countryErr,
      postalCode,
      postalCodeErr,
      city,
      cityErr,
      description,
      descriptionErr,
    } = this.state;

    if (
      nameErr === "ss" &&
      familyErr === "ss" &&
      emailErr === "ss" &&
      mobileErr === "ss" &&
      addressErr === "ss" &&
      turkishIDErr === "ss" &&
      countryErr === "ss" &&
      postalCodeErr === "ss" &&
      cityErr === "ss" &&
      descriptionErr === "ss"
    ) {
      this.setState({ loading: true });

      RequestServer(
        "post",
        "personal-payment-gateway/start",
        (response) => {
          this.setState({ url: response.url });
          if (response.redirect) {
            this.pay(response.url);
          } else {
            this.setState({
              tryAmount: response.amount,
              rate: response.rate,
              loading: false,
              companyName: response.company_name,
              trySweetAlert: true,
            });
          }
        },
        {
          post: {
            payer_turkish_id: turkishID,
            currency_id: selCurrency.value.id,
            amount,
            payer_first_name: name,
            payer_last_name: family,
            payer_email: email,
            payer_mobile: mobile,
            payer_postal_code: postalCode,
            payer_country: country,
            payer_description: description,
            payer_city: city,
            payer_address: address,
            username: this.props.match.params.username,
            lang: this.lang,
            source: "Website",
          },
          lang: this.lang,
          thisProps: { data: bootData, showModal: this.props.showModal },
          onAnyFailed: () => {
            this.setState({ loadingForm: false, loading: false });
          },
        }
      );
    } else {
      if (turkishID === "")
        this.setState({
          focus: "turkishID",
          turkishIDErr: errorsStrings[this.lang].inputRequired,
        });

      if (city === "")
        this.setState({
          focus: "city",
          cityErr: errorsStrings[this.lang].inputRequired,
        });

      if (postalCode === "")
        this.setState({
          focus: "postalCode",
          postalCodeErr: errorsStrings[this.lang].inputRequired,
        });

      if (country === "")
        this.setState({
          focus: "country",
          countryErr: errorsStrings[this.lang].inputRequired,
        });

      if (address === "")
        this.setState({
          focus: "address",
          addressErr: errorsStrings[this.lang].inputRequired,
        });

      if (mobile === "")
        this.setState({
          focus: "mobile",
          mobileErr: errorsStrings[this.lang].inputRequired,
        });

      if (email === "")
        this.setState({
          focus: "email",
          emailErr: errorsStrings[this.lang].inputRequired,
        });

      if (family === "")
        this.setState({
          focus: "family",
          familyErr: errorsStrings[this.lang].inputRequired,
        });

      if (name === "")
        this.setState({
          focus: "name",
          nameErr: errorsStrings[this.lang].inputRequired,
        });

      if (amount === "")
        this.setState({
          focus: "amount",
          amountErr: errorsStrings[this.lang].inputRequired,
        });

      if (selCurrency === "")
        this.setState({
          focus: "selCurrency",
          selCurrencyErr: errorsStrings[this.lang].inputRequired,
        });

      if (description === "")
        this.setState({
          focus: "description",
          descriptionErr: errorsStrings[this.lang].inputRequired,
        });
    }
    e.preventDefault();
  };
}

export default connect(null, { showModal })(PaymentLink);
