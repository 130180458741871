import React from "react";
import languages from "../../languages";
import { Route, Switch } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getLang, setLink, userIsLoggedIn } from "../../Values";

// core components
import RTLNavbar from "../../components/Navbars/RTLNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";

import routes from "../../routes.js";
import ModalRoot from "../../dialogs/ModalRoot";

var ps;

class Admin extends React.Component {
  constructor(props) {
    console.log(props, "main");

    super(props);
    this.state = {
      activeColor: "primary",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };

    const lang = props.match.params.lang;
    if (!lang || !["en", "fa"].includes(lang)) {
      if (props.location.pathname === "/") {
        props.history.push(setLink("/auth/login", lang));
        window.location.reload();
      } else {
        props.history.replace(setLink(props.location.pathname, "en"));
        window.location.reload();
      }
    } else if (!userIsLoggedIn(this.props.userData)) {
      props.history.push(setLink("/auth/login", lang));
      window.location.reload();
    }

    // if(this.props.userData.email_status === "Inactive") {
    //     let emailActivateRoute = routes.find(prop => {
    //         return prop.path === "/email-check";
    //     });
    //     return [
    //         <Route
    //             path={"/"}
    //             component={emailActivateRoute.component}
    //             key={0}
    //         />
    //     ];
    // }
  }
  componentDidMount() {
    document.body.classList.add("white-content");
    document.body.classList.remove("sidebar-mini");
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
    this.lang = getLang(this.props);
    if (this.lang === "fa") {
      document.body.classList.add("rtl", "menu-on-right");
      document.documentElement.classList.add("rtl-active");
    } else {
      document.body.classList.remove("rtl", "menu-on-right");
      document.documentElement.classList.remove("rtl-active");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  showNavbarButton = () => {
    if (
      (document.documentElement.scrollTop > 50 ||
        document.scrollingElement.scrollTop > 50 ||
        this.refs.mainPanel.scrollTop > 50) &&
      this.state.opacity === 0
    ) {
      this.setState({ opacity: 1 });
    }
    if (
      document.documentElement.scrollTop <= 50 &&
      document.scrollingElement.scrollTop <= 50 &&
      this.refs.mainPanel.scrollTop <= 50 &&
      this.state.opacity === 1
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={`/:lang(fa|en)?${prop.path}`}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = (routes) => {
    let activeRoute = languages[this.lang].yekpaydashboard;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (this.props.location.pathname.includes(routes[i].path))
          return this.lang === "fa" ? routes[i].rtlName : routes[i].name;
      }
    }
    return activeRoute;
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      // notifyMessage += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      // notifyMessage += "activated...";
    }
    // let options = {};
    // options = {
    //   place: "tc",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };
  render() {
    this.lang = getLang(this.props);
    const { userData, data, location, history } = this.props;

    if (
      !location.pathname.includes("email-check") &&
      userIsLoggedIn(userData) &&
      userData.email_status !== "Active"
    )
      history.replace(setLink("/email-check", this.lang));

    const plan =
      data && data.plans
        ? data.plans.find((item) => item.id === userData.plan_id)
        : null;

    let planName = languages[this.lang].personal;
    if (plan) {
      if (plan.id === 2) planName = languages[this.lang].business;
      else if (plan.id === 3) planName = languages[this.lang].premiumbusiness;
    }

    const title = this.getActiveRoute(routes);
    document.title = `${languages[this.lang].yekpay} - ${title}`;

    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          rtlActive={this.lang === "fa"}
          logo={{
            innerLink: `/${this.lang}/profile`,
            text: userData.first_name + " " + userData.last_name,
            text2: planName,
            imgSrc: userData.profile_picture
              ? userData.profile_picture
              : undefined,
          }}
          username={userData.username}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
        >
          <ModalRoot />
          <RTLNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={title}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect to={setLink("/not-found", this.lang)} />
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid lang={this.lang} />
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer,
    data: state.dataReducer,
  };
};

export default withRouter(connect(mapStateToProps)(Admin));
